import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import Loader from "@shared/loader";
import Empty from "@shared/empty";
const ListModal = () => import("@shared/components/listModal");
import Button from "@shared/components/button";
import { FormatDateNew } from "@/plugins/functions"
import dropdown from "@shared/components/dropdown-base";
import { uuid } from "vue-uuid";

export default {
    name: "licence-list",
    components: {
        SubHeader,
        Loader,
        Empty,
        ListModal,
        Button,
        dropdown,
    },
    props: {},
    data: () => ({
        columns: [
            {
                label: "License Name",
                field: "name",
                sortable: true,
                filterOptions: {
                    enabled: true, // enable filter for this column
                    multi: true,
                    isLoading: false,
                    query_key: "name",
                },
            },
            {
                label: "Description",
                field: "description",
                sortable: true,
                filterOptions: {
                    enabled: true, // enable filter for this column
                    multi: true,
                    isLoading: false,
                    query_key: "description",
                },
            },
            {
                label: "Assigned Tenants",
                field: "tenants",
                filter_type: "tenant_users",
                sortable: true,
                // filterOptions: {
                //     enabled: true, // enable filter for this column
                //     multi: true,
                //     isLoading: false,
                //     query_key: "tenants",
                // },
            },
            {
                label: "VALIDITY",
                field: "validity",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },
        ],
        // dummy Data
        rows: [],
        filteredRowsData: [],
        filters: {},
        loadingStates: {
            fetchingLicence: false,
            listModal: false,
            submit: false
        },
        licenseTenants: [],
        currentLicense: null,
        serchValues: ""
    }),

    computed: {
        getlicenseTenants() {
            return this.licenseTenants?.filter((el) => el.title?.toLowerCase()?.includes(this.serchValues.toLowerCase()));
        },
    },
    async mounted() {
        await this.getLicensePack();
        this.filteredRowsData = this.rows;
    },
    methods: {
        createClicense() {
            this.$router.push({
                name: "license-add",
            });
        },
        async getLicensePack() {
            this.loadingStates.fetchingLicence = true;
            this.serchValues = ""
            try {
                let { data } = await axios.get("/license-pack");
                this.rows = data?.data || [];
            } catch (error) {
                console.log(error, "error");
            }
            this.loadingStates.fetchingLicence = false;
        },
        async handleShowModal(id) {
            this.serchValues = ""
            this.currentLicense = id
            this.$refs["license-tenants-modal"].showListModal();
            await this.getLicenseTenantsData(id);
        },
        handleCloseModal() {
            this.serchValues = ""
            this.currentLicense = null
            this.$refs["license-tenants-modal"].closeModal();
        },
        async getLicenseTenantsData(id) {
            this.serchValues = ""
            this.loadingStates.listModal = true;
            try {
                let {data} = await axios.get(`/license-pack/${id}/assigned_tenants`);
                this.licenseTenants =  data.assigned_tenants.map(el => ({...el, id:el.tenant_id})) || []
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.listModal = false;
        },
        removeTenants(tenant_id){
            this.licenseTenants = this.licenseTenants.filter(el => el.id !== tenant_id )
        },
        async assignTenants(licenseId){
            this.loadingStates.submit = true;
            try {
                let payload = {
                    license_pack_id: licenseId,
                    tenants: this.licenseTenants.map((tenant) => ({ tenant_id: tenant.id, license_count: tenant.license_count })),
                };
                let { data } = await axios.post(`/license-pack/allocate-tenant`, payload);
                this.$toast.success(data.message || "tenant assigned");
                this.handleCloseModal()
                this.getLicensePack()
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.submit = false;  
        },
        handleFilterResult(event) {
            this.serchValues = event;
        },
        getFilterOptions(column){
            // get unique filter options array
            let filterData = []
            if (column?.filter_type == 'client_users' && this.rows?.length > 0) {
                this.rows.forEach((rowfilter) => {
                    rowfilter?.clients?.map((rowData) => {
                        if (!filterData?.some?.(data => data?.name == rowData?.client_name)) {
                            filterData?.push(
                                {
                                    id: rowData?.client_id,
                                    name: rowData?.client_name
                                }
                                )
                            }
                    })
                })
            } if (column?.filter_type == 'tenant_users' && this.rows?.length > 0) {
                this.rows.forEach((rowfilter) => {
                    rowfilter?.tenants?.map((rowData) => {
                        if (!filterData?.some?.(data => data?.name == rowData?.user_name)) {
                            filterData?.push(
                                {
                                    id: rowData?.user_id,
                                    name: rowData?.user_name
                                }
                                )
                            }
                    })
                })
            } else {
                filterData = this.rows
                .filter((row) => row[column.field])
                .map((row) => (
                    { 
                        id: row.field_type_id ?? uuid.v4(), 
                        label: row[column.field],
                        name: column?.filter_type == 'date' ? FormatDateNew(new Date (row[column.field])) : row[column?.field]
                    }
                )
                )
                if (filterData && filterData?.length > 0) {
                    const uniqueMap = new Map();
                    filterData = filterData.forEach(item => {
                      uniqueMap.set(item.name, item);
                    });
                    return Array.from(uniqueMap.values());
                }
            }
            return filterData;
        },
        updateColumnFilters(column, value){
            if (column?.filter_type == 'date') {
                this.filters[column.field]  =  value.label ? [value.label] : [] ;
            } else {
                this.filters[column.field]  =  value.name ? [value.name] : [] ;
            }
            let result = this.rows;
            for (let filter in this.filters) {
                if (this.filters[filter]?.length) {
                    if (column?.filter_type == 'client_users') {
                        result = result?.filter((el) => el?.clients?.some(clnt => clnt?.client_name == value?.name))
                    } if (column?.filter_type == 'tenant_users') {
                        result = result?.filter((el) => el?.tenant_users?.some(tnt => tnt?.user_name == value?.name))
                    } else {
                        result = result.filter((el) => this.filters[filter].includes(el[filter]));
                    }
                }
            }
            this.filteredRowsData = result;
        },
        clearFilters() {
            this.filters = {};
            this.filteredRowsData = this.rows;
        },
    },
};
